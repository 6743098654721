import React from 'react';
import Layout from '../layouts/blog/layout';
import { graphql } from 'gatsby';
import ArticleItem from '../page-components/blog/article-item/article-item';
import { get } from 'lodash';

const Home = ({ data, location }) => {
  const items = get(data, 'allMdx.nodes');

  return <Layout location={location}>{items ? items.map((article) => <ArticleItem data={article} />) : null}</Layout>;
};

export const pages = graphql`
  query AuthorPosts($author: String!) {
    allMdx(
      filter: { frontmatter: { type: { in: "post" }, author: { in: [$author] } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          thumbnail
          permalink
          author
          date
          category
          title
        }
        excerpt(pruneLength: 200)
      }
      pageInfo {
        pageCount
        hasNextPage
        hasPreviousPage
        currentPage
      }
    }
  }
`;

export default Home;
